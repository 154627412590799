import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { SEO, Text, Flex, BackgroundImage } from "components";
import { useIntl } from "hooks";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Background = styled.div`
  height: 100vh;
  min-width: 100%;
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  overflow: hidden;
`;

const Overlay = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.2);
`;

const TextFlex = styled(Flex)`
  border-radius: 9px;
`;

const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 800px;

  color: ${p => p.theme.colors.black};
  text-align: left;
  margin-bottom: 40px;

  h2 {
    max-width: 600px;
    margin: 15px 0;
    &::first-letter {
      color: ${p => p.theme.colors.brand};
    }
  }

  p {
    max-width: 600px;
    line-height: 1.4;
  }

  hr {
    border: 1px solid ${p => p.theme.colors.brand};
    width: 90px;
    margin: 30px 0;
  }
`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const IndexPage = () => {
  const locale = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "wheeds_sun.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          nl: contentfulFaq(node_locale: { regex: "/nl/" }) {
            title
            question {
              json
            }
          }
          en: contentfulFaq(node_locale: { regex: "/en/" }) {
            title
            question {
              json
            }
          }
        }
      `}
      render={data => {
        console.log(data);
        const content = data[locale];
        return (
          <>
            <SEO title="Frequently asked questions" />
            <Background>
              <BackgroundImage
                durationFadeIn={300}
                loading="eager"
                fluid={data.placeholderImage.childImageSharp.fluid}
              />
              <Overlay />
            </Background>
            <Flex
              width="100%"
              mt={{ _: "100px", sm: "200px" }}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              px={{ _: "20px", sm: "80px", sm: "160px" }}
            >
              <Text
                color="dark"
                mb="20px"
                as="h1"
                fontSize={{ _: "50px", sm: "70px" }}
              >
                {content.title}
              </Text>
              <TextFlex
                flexDirection="column"
                p={{ _: "20px 40px", sm: "40px 80px" }}
                maxWidth="900px"
                bg="rgba(255,255,255,1)"
                mt="50px"
                mb="200px"
                alignItems="center"
              >
                <Feature>
                  {documentToReactComponents(content.question.json)}
                </Feature>
              </TextFlex>
            </Flex>
          </>
        );
      }}
    />
  );
};

export default IndexPage;
